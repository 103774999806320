import { default as _playgroundElci6YjxDWMeta } from "/vercel/path0/pages/_playground.vue?macro=true";
import { default as aboutcggDDldG7iMeta } from "/vercel/path0/pages/about.vue?macro=true";
import { default as accountLUTTRGMhg3Meta } from "/vercel/path0/pages/account.vue?macro=true";
import { default as _91_46_46_46slug_93QvcqcC3xvKMeta } from "/vercel/path0/pages/blog/[...slug].vue?macro=true";
import { default as indexmfxAx2eWMBMeta } from "/vercel/path0/pages/blog/index.vue?macro=true";
import { default as bookzCMrXEiEIzMeta } from "/vercel/path0/pages/book.vue?macro=true";
import { default as _91id_93wq1t1YAQOBMeta } from "/vercel/path0/pages/bookings/[id].vue?macro=true";
import { default as indexlhRkTHZvawMeta } from "/vercel/path0/pages/bookings/index.vue?macro=true";
import { default as indexe9Brt5DfdhMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as _91id_93moV6CvcUIdMeta } from "/vercel/path0/pages/invoices/[id].vue?macro=true";
import { default as _91id_93bKqfojxKekMeta } from "/vercel/path0/pages/locations/[id].vue?macro=true";
import { default as indexzKnoFAVuQpMeta } from "/vercel/path0/pages/locations/index.vue?macro=true";
import { default as logint0AWlhQgM0Meta } from "/vercel/path0/pages/login.vue?macro=true";
import { default as logoutBqDDZXgXsyMeta } from "/vercel/path0/pages/logout.vue?macro=true";
import { default as maintenanceLjk4bqrWMWMeta } from "/vercel/path0/pages/maintenance.vue?macro=true";
import { default as personal_45trainersxnfPNrVGeMMeta } from "/vercel/path0/pages/personal-trainers.vue?macro=true";
import { default as phone_45number7lhDV6WmBIMeta } from "/vercel/path0/pages/phone-number.vue?macro=true";
import { default as privacykJ3SQnmrN4Meta } from "/vercel/path0/pages/privacy.vue?macro=true";
import { default as providersYf2aRO1VmaMeta } from "/vercel/path0/pages/providers.vue?macro=true";
import { default as rulesn6GEvFMS8YMeta } from "/vercel/path0/pages/rules.vue?macro=true";
import { default as servicesoiqz5GGY44Meta } from "/vercel/path0/pages/services.vue?macro=true";
import { default as termsq5qlZHSSonMeta } from "/vercel/path0/pages/terms.vue?macro=true";
import { default as component_45stubHiPLv5sjiIMeta } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubHiPLv5sjiI } from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@22.7.7_encoding@0.1.13_guwhg75tdhm5mnzi4qb2ofqriu/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "_playground",
    path: "/_playground",
    component: () => import("/vercel/path0/pages/_playground.vue")
  },
  {
    name: "about",
    path: "/about",
    component: () => import("/vercel/path0/pages/about.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/vercel/path0/pages/account.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug(.*)*",
    component: () => import("/vercel/path0/pages/blog/[...slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/vercel/path0/pages/blog/index.vue")
  },
  {
    name: "book",
    path: "/book",
    meta: bookzCMrXEiEIzMeta || {},
    component: () => import("/vercel/path0/pages/book.vue")
  },
  {
    name: "bookings-id",
    path: "/bookings/:id()",
    component: () => import("/vercel/path0/pages/bookings/[id].vue")
  },
  {
    name: "bookings",
    path: "/bookings",
    meta: indexlhRkTHZvawMeta || {},
    component: () => import("/vercel/path0/pages/bookings/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "invoices-id",
    path: "/invoices/:id()",
    component: () => import("/vercel/path0/pages/invoices/[id].vue")
  },
  {
    name: "locations-id",
    path: "/locations/:id()",
    meta: _91id_93bKqfojxKekMeta || {},
    component: () => import("/vercel/path0/pages/locations/[id].vue")
  },
  {
    name: "locations",
    path: "/locations",
    meta: indexzKnoFAVuQpMeta || {},
    component: () => import("/vercel/path0/pages/locations/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logint0AWlhQgM0Meta || {},
    component: () => import("/vercel/path0/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/vercel/path0/pages/logout.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/vercel/path0/pages/maintenance.vue")
  },
  {
    name: "personal-trainers",
    path: "/personal-trainers",
    component: () => import("/vercel/path0/pages/personal-trainers.vue")
  },
  {
    name: "phone-number",
    path: "/phone-number",
    meta: phone_45number7lhDV6WmBIMeta || {},
    component: () => import("/vercel/path0/pages/phone-number.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    component: () => import("/vercel/path0/pages/privacy.vue")
  },
  {
    name: "providers",
    path: "/providers",
    meta: providersYf2aRO1VmaMeta || {},
    component: () => import("/vercel/path0/pages/providers.vue")
  },
  {
    name: "rules",
    path: "/rules",
    component: () => import("/vercel/path0/pages/rules.vue")
  },
  {
    name: "services",
    path: "/services",
    meta: servicesoiqz5GGY44Meta || {},
    component: () => import("/vercel/path0/pages/services.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("/vercel/path0/pages/terms.vue")
  },
  {
    name: component_45stubHiPLv5sjiIMeta?.name,
    path: "/monitor",
    component: component_45stubHiPLv5sjiI
  }
]